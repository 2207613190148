/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
@import 'animations';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Fjalla One', Impact, Verdana, Arial, Helvetica, sans-serif;
}
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // overflow-x: hidden;
  // scroll-behavior: smooth;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family:
    'Open Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #ffffff;
  color: #999999;
  border-top: 1px solid #e4e4e4;
  // p {
  // 	margin: 0 0 5px 0;
  // }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  .disclaimer {
    font-size: 0.85rem;
  }
  .nav-links {
    padding-left: 0;
    li {
      display: inline;
    }
  }
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 60%;
    max-width: 650px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: 100%;
      height: auto;
      max-width: 300px;
      &.affiliate {
        max-width: 60px;
      }
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family:
        'Fjalla One',
        'Open Sans',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        'Noto Sans',
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji';
    }
    span.affiliate-logo-text {
      display: none;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1.2em;
      vertical-align: middle;
      font-weight: 600;
      font-family:
        'Fjalla One',
        'Open Sans',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        'Noto Sans',
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji';
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: 'Open Sans', Verdana;
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      // float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      font-size: 15px;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}
#sent-banner {
  display: none;
  background: rgba(47, 175, 47, 0.95);
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}
#phones {
  background: #1976d2;
  color: #fff;
  font-size: 0.9rem;
}
#header .agency {
  display: none;
  float: right;
  clear: right;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 18px;
  font-family: 'Fjalla One', Impact, Verdana;
  color: #999;
}
#support-wrap {
  border-top: 1px solid #fff;
  // border-bottom: 1px solid #fff;
  background-color: $theme-primary;
  div.col-6:first-child {
    border-right: 1px solid darken($theme-primary, 7.5%);
  }
  a {
    padding: 5px 0;
    color: #fff;
    background-color: $theme-primary;
    border: 0;
    &:hover {
      background-color: darken($theme-primary, 5%);
    }
    &.login:before,
    &.livechat:before {
      display: inline-block;
      margin-right: 5px;
      width: inherit;
      height: inherit;
      content: '\e828';
      color: inherit;
      font-family: 'icons';
      font-weight: normal;
      text-align: center;
      font-size: 105%;
      vertical-align: top;
    }
    &.livechat:before {
      content: '\e830';
    }
  }
}
#banner-wrap {
  background: #b4c4d3 url(../images/banner-bg-sm-lp.jpg) no-repeat 50% 100% / cover;
  border-bottom: 1px solid #ccc;
  &.spanish {
    background: #b4c4d3 url(../images/banner-bg-sm.jpg) no-repeat 50% 100% / cover;
    h1 {
      color: #000 !important;
      text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.75) !important;
    }
    .pitch {
      background-color: rgba(255, 255, 255, 0.8);
      h2 {
        margin: 0;
        padding: 0 15px 10px 15px;
        color: #c00;
        font-family: 'Open Sans', Verdana, Tahoma, Helvetica, Arial, sans-serif;
        line-height: 1.3;
        font-weight: 700 !important;
        position: relative;
        span {
          display: block;
          margin-top: 5px;
          color: #333;
          font-size: 16px;
        }
        &:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 25px 0 25px 15px;
          border-color: transparent transparent transparent #1975d2;
          position: absolute;
          top: 50%;
          left: -16px;
          margin-top: -30px;
        }
      }
      h3 {
        margin-top: 0;
        padding: 8px 30px;
        background: #1976d2;
        color: #fff;
        font-family: 'Open Sans', Verdana, Tahoma, Helvetica, Arial, sans-serif;
        font-weight: 700 !important;
      }
      ol {
        padding-left: 30px;
        color: #333;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  &.inner-banner,
  &.insurance {
    background: #b4c4d3 url(../images/insurance-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.defensive {
    background: #b4c4d3 url(../images/defensive-driving-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.about {
    background: #b4c4d3 url(../images/about-bg-sm.jpg) no-repeat 50% 50% / cover;
  }
  &.course-catalog {
    background: #b4c4d3 url(../images/course-catalog-bg-sm.jpg) no-repeat 50% 50% / cover;
    .cta-btn {
      max-width: 260px;
    }
  }
  &.renewal {
    background: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff8e2), to(#f0f7ff));
    background-image: linear-gradient(#fff8e2, #f0f7ff);
    h1 {
      color: #000 !important;
      text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.75) !important;
    }
    ul {
      color: #555;
      font-size: 1rem !important;
      font-weight: bold;
    }
  }
  #banner {
    color: #ffffff;
    h1 {
      color: #fff;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.75);
      letter-spacing: 0.5px;
    }
    p.banner-text-shadow {
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.75);
    }
    ul:not(.drop) {
      font-size: 1.1rem;
      font-weight: 600;
      a {
        color: inherit;
        border-bottom: 1px dotted #ccc;
      }
    }
    .action {
      .cta-btn {
        margin-left: 10px;
        margin-right: 10px;
      }
      .price {
        color: #ffffff;
        margin-left: 5px;
        margin-right: 5px;
      }
      a.spanish-btn {
        display: inline-block;
        color: #555;
        font-family: 'Fjalla One', 'Trebuchet MS', Tahoma, Verdana, Helvetica, Arial, sans-serif;
        font-size: 18px;
        vertical-align: middle;
        border: 0;
      }
    }
  }
}
.main-content-lp {
  h2,
  h3 {
    color: #00366b;
  }
  .card h3 {
    background: $theme-primary;
  }
  .cta-btn {
    min-width: 0 !important;
  }
  .dropdown-list span.dropdown:hover {
    background: darken($cta-red, 7%);
  }
}
.dropdown-list {
  width: 100%;
  max-width: 300px;
  span.dropdown.cta-dropdown {
    padding: 16px;
    padding-bottom: 14px;
    color: #fff;
    font-weight: 400;
    background: $cta-red;
    border: 0;
    width: 100%;
    height: auto;
    line-height: 1;
    font-size: 22px;
    text-align: left;
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    & + .drop {
      top: 50px;
      color: #555 !important;
      font-weight: normal !important;
    }
  }
}
#icons-wrap {
  background-color: #fafafa;
  .icon-col {
    border-bottom: 1px dotted #ccc;
    &:last-child {
      border: 0;
    }
  }
  img {
    max-width: 35px;
    height: auto;
  }
  span {
    font-weight: 600;
    color: #00366b;
  }
  &.four-icons {
    img {
      max-width: 50px;
      height: auto;
    }
  }
}
#insurance-wrap {
  background: #1976d2;
  max-height: 235px;
  min-height: 156px;
  color: #fff;
}
#video-wrap {
  background: #fafafa;
  border-bottom: 1px solid #e5ecf4;
  a {
    display: inline-block;
    color: #666;
    border: 0;
    position: relative;
    span {
      display: inline-block;
      font-family: 'Fjalla One', 'Trebuchet MS', Tahoma, Verdana, Helvetica, Arial, sans-serif;
      font-size: 1.2rem;
      line-height: 1.3;
      text-align: left;
      vertical-align: middle;
    }
    &:before {
      display: inline-block;
      margin-right: 10px;
      width: 52px;
      height: 40px;
      content: '';
      background: url(../images/video-icon.png) no-repeat 0 0 / cover;
      vertical-align: middle;
    }
  }
}
.main-content {
  h2 a {
    color: #c43;
  }
}
#footer-home-cta {
  border-top: 1px solid #e5ecf4;
  background: #fafafa;
  .cta {
    font-size: 1.6rem;
    line-height: 1.4;
    font-family: 'Fjalla One', 'Trebuchet MS', Tahoma, Verdana, Helvetica, Arial, sans-serif;
    vertical-align: middle;
  }
}
#footer-cta {
  border-top: 1px solid #e5ecf4;
  background: #e5f4ff;
  .cta {
    font-size: 1.6rem;
    line-height: 1.4;
    font-family: 'Fjalla One', 'Trebuchet MS', Tahoma, Verdana, Helvetica, Arial, sans-serif;
    vertical-align: middle;
  }
  a.spanish-btn {
    display: inline-block;
    color: #555;
    font-family: 'Fjalla One', 'Trebuchet MS', Tahoma, Verdana, Helvetica, Arial, sans-serif;
    font-size: 18px;
    vertical-align: middle;
    border: 0;
  }
}
#reviews-wrap {
  background: #00366b;
  color: #fff;
  img.award {
    max-width: 300px;
    width: 100%;
    height: auto;
  }
  a {
    color: inherit;
  }
  .review {
    font-size: 1.3rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style: italic;
  }
  img.approved {
    margin-top: -10px;
    margin-left: 10px;
    vertical-align: middle;
  }
}
a.phone i {
  display: inline-block;
  margin: 0 10px 0 0;
  height: 35px;
  width: 35px;
  font-size: 1.4em;
  color: #ccc;
  line-height: 30px;
  border-radius: 50%;
  border: 2px solid #ccc;
  vertical-align: middle;
}
.card-action {
  background: #fff url(../images/section_bg.jpg) no-repeat top right;
}
aside {
  .card {
    background: #fff url(../images/section_bg.jpg) no-repeat top right;
  }
  .video,
  .livechat {
    padding: 25px 15px 25px 80px;
    background: transparent url(../images/play-icon.png) no-repeat 15px 50% / 50px 50px;
    font-family: 'Fjalla One', 'Trebuchet MS', Tahoma, Verdana, Helvetica, Arial, sans-serif;
    color: #555;
    font-size: 18px;
    line-height: 1.4;
  }
  .livechat {
    background: transparent url(../images/chat-icon.png) no-repeat 15px 50% / 50px 50px;
  }
}
/* Custom accordion style */
.accordion-wrap {
  .accordion {
    .accordion-toggle:before {
      color: #1976d2;
    }
  }
  &.faqs {
    .accordion-toggle {
      color: #333;
      font-family: inherit;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
.city-list {
  ul {
    list-style-type: none;
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #banner-wrap {
    h1 {
      font-size: 2rem;
    }
  }
  #icons-wrap {
    .icon-col {
      &:nth-child(n + 2) {
        border: 0;
      }
    }
    &.four-icons {
      .icon-col {
        border-bottom: 1px dotted #ccc;
        border-right: 1px dotted #ccc;
        &:nth-child(n + 2) {
          border-bottom: 1px dotted #ccc;
        }
        &:nth-child(n + 3) {
          border-bottom: 0;
        }
        &:nth-child(2),
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
  #video-wrap {
    a {
      span {
        font-size: 1.5rem;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      max-width: 300px;
      margin-right: 15px;
      &.affiliate {
        max-width: 100%;
      }
    }
    span.big {
      display: inline-block;
      font-size: 1.9em;
    }
    span.small {
      display: inline-block;
    }
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #support-wrap {
    display: none;
  }
  #banner-wrap {
    h1 {
      font-size: 2.3rem;
    }
    &.spanish {
      h1 {
        font-size: 2.7rem;
      }
    }
  }
  .content-icon {
    text-align: center;
    .icon {
      display: inline-block;
      padding-top: 170px;
      height: 0;
      width: 100%;
      max-width: 170px;
      vertical-align: middle;
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
      &.car-icon {
        background-image: url(../images/car-icon-alt.png);
      }
      &.car-alt-icon {
        background-image: url(../images/car-icon.png);
      }
      &.money-icon {
        background-image: url(../images/money-icon-alt.png);
      }
      &.license-icon {
        background-image: url(../images/license-icon-alt.png);
      }
    }
  }
  .img-clear {
    clear: left;
  }
}
// roughly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 75%;
    span.small {
      font-size: 18px;
    }
    span.affiliate-logo-text {
      display: inline-block;
    }
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #banner-wrap {
    background-image: url(../images/banner-bg-lp.jpg);
    &.spanish {
      background-image: url(../images/banner-bg.jpg);
      #banner {
        background: transparent url(../images/smiling-lady.png) no-repeat 35px 110%;
        position: relative;
        h1 {
          font-size: 3.5rem;
        }
        &:after {
          display: block;
          content: url('/images/online-seal.png');
          position: absolute;
          left: 33%;
          bottom: 40%;
        }
      }
    }
    &.renewal {
      background: #f9fdfe;
      #banner {
        background: #f9fdfe url(../images/banner_bg_renew.jpg) no-repeat 15px bottom / cover;
      }
      h1 {
        font-size: 3.2rem;
      }
    }
    &.inner-banner,
    &.insurance {
      background-image: url(../images/insurance-bg.jpg);
    }
    &.defensive {
      background-image: url(../images/defensive-driving-bg.jpg);
    }
    &.about {
      background-image: url(../images/about-bg.jpg);
    }
    &.course-catalog {
      background-image: url(../images/course-catalog-bg.jpg);
    }
    h1 {
      font-size: 2.7rem;
    }
  }
  #icons-wrap {
    .icon-col {
      border-bottom: 0;
    }
    img {
      max-width: 100%;
    }
  }
}

@media (min-width: 1170px) {
  #header .agency {
    display: block;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #icons-wrap {
    &.four-icons {
      .icon-col {
        border-bottom: 0;
        &:nth-child(n + 2) {
          border-bottom: 0;
        }
        &:nth-child(2) {
          border-right: 1px dotted #ccc;
        }
      }
    }
  }
}
