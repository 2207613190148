// all elements that is being animated will need to have the class .scroll-anim
.scroll-anim {
  opacity: 0;
  position: relative;
}

.fade-in {
  animation: fadeInAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.fade-in-quick {
  animation: fadeInAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-out-quick {
  animation: fadeOutAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.slide-in-top {
  animation: slideInTopAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes slideInTopAnimation {
  0% {
    opacity: 0;
    // top: -100%;
    transform: translateY(-100%);
  }
  75% {
    // top: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}

.slide-in-bottom {
  animation: slideInBottomAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes slideInBottomAnimation {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}

.slide-in-left {
  animation: slideInLeftAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes slideInLeftAnimation {
  0% {
    opacity: 0;
    transform: translateX(-75%);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}

.slide-in-right {
  animation: slideInRightAnimation cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes slideInRightAnimation {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
